<template>
  <div class="not-found"></div>
</template>

<script>
export default {
  name: "404",
};
</script>

<style lang="less" scoped>
.not-found {
  width: 100%;
  height: 100vh;
  background: url(../../static/image/404.png) no-repeat;
  background-size: 100% 100%;
}
</style>
